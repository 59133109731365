// Your custom variables
$site-color: #00b842;
$color-border-gray: #e6e6e6;
$color-text-gray: #969696;
$color-text-gray2: #757575;
$color-text-gray3: #646464;
$color-text-gray4: #e6e6e6;
$primary-color: #00b842 !default; 
$color-close: #969696;
$color-bg-green: #eef1e4;
$color-supplier: #ff8c1e;
$color-buyer: #50b4fa;
$color-menu-hover: #ace639;
$color-tag: #ace639;

$shadow_1: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important;

$color-en: #ACE639;


$mall-color1: #512700; //焦げ茶 ボーダー・テキスト・ボタン
$mall-color2: #009D8B; //明るい緑 テキストリンク・ボタン
$mall-color3: #C59C5C; //明るい茶 ボーダー・ボタン・見出し
$mall-color4: #FDF8EB; //ベージュ 背景


$mall-disabled: #969696;//非活性
$mall-hover: #ACE639; //ホバー
$mail-border: #E6E6D2;//グレー ボタンボーダー

$kit-color1: #FF6589;
$kit-color2: #50B3FA;
$kit-color3: #CDD6DD;

