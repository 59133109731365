@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.business-meeting {
  // エリア
  max-width: 1110px;
  margin: 0 auto 40px;

  // 登録ボタン
  .regist-button {
    text-align: center;
  }

  // デイトピッカー
  .react-datepicker {
    &__day {
      &--today {
        background-color: #deebe0;
        font-weight: 300;
      }
      &--selected {
        color: #000;
        background-color: #3dcc4a;
      }
      &--highlighted {
        color: #000;
        background-color: #fbd9d9;
      }
    }
  }

  // 商談実績カード検索
  .search {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 40px;
    &__select {
      width: calc((100% - 60px) / 3);
    }
    &__date {
      width: 100%;
      padding-bottom: 30px;
      border-bottom: 1px solid #ced4da;
      &--block {
        display: flex;
        align-items: center;
        gap: 8px;
        & > div {
          display: flex;
          align-items: center;
          padding-inline: 10px;
          border: solid 1px #CED4DA;
          border-radius: 4px;
          label {
            color: #757575;
            margin: 0;
          }
          .react-datepicker__calendar-icon {
            width: 20px;
          }
          input {
            width: 160px;
          }
        }
      }
    }
    &__btn {
      width: 100%;
      margin-top: calc(-.42rem - (7px * 1.5) - 30px);
      text-align: center;
    }
    strong {
      font-size: 18px;
      width: 100%;
    }
    label {
      font-size: .8rem;
      cursor: text;
      color: #00b842;
    }
    input {
      outline: none;
    }
    .react-datepicker-popper {
      z-index: 500;
    }
  }

  // 商談実績カード一覧
  .list {
    margin-top: 35px;
    &__head {
      display: flex;
      gap: 18px;
      p {
        &:nth-of-type(2) {
          margin-left: auto;
        }
      }
    }
    &__flow {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 0;
      margin-top: 20px;
      &--title {
        display: block;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        padding: 2px 20px;
        position: relative;
        &::after {
          content: '';
          height: 100%;
          aspect-ratio: 1/1;
          border-right: solid 2px #fff;
          border-radius: 0 9999px 9999px 0;
          position: absolute;
          top: 0;
          right: -8px;
          z-index: 100;
        }
      }
      &--dropzone {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 16px;
      }
      &--step {
        width: 25%;
        border-radius: 4px;
        position: relative;
        &[data-step="1"] {
          background-color: #F5FCFF;
          .list__flow--title {
            background-color: #00A8F2;
            &::after {
              background-color: #00A8F2;
            }
          }
        }
        &[data-step="2"] {
          background-color: #EFF1FC;
          .list__flow--title {
            background-color: #0027FF;
            &::after {
              background-color: #0027FF;
            }
          }
        }
        &[data-step="3"] {
          background-color: #FDFAF7;
          .list__flow--title {
            background-color: #F27600;
            &::after {
              background-color: #F27600;
            }
          }
        }
        &[data-step="4"] {
          background-color: #F0FDF5;
          .list__flow--title {
            background-color: #00B842;
            &::after {
              content: none;
            }
          }
        }
        &[data-step="5"] {
          background-color: #E6E6E6;
          width: 100%;
          .list__flow--title {
            background-color: #757575;
            border-radius: 4px 4px 0 0;
            &::after {
              content: none;
            }
          }
          .list__card {
            width: calc((100% - 104px) / 4);
            &:nth-of-type(n + 2) {
              margin-top: 0;
            }
          }
          .list__flow--dropzone {
            flex-direction: row;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 34px;
          }
        }
      }
      &--drop {
        height: calc(100% - 32px);
        padding: 16px;
      }
    }
    &__card {
      background-color: #fff;
      padding: 12px;
      border-radius: 4px;
      box-shadow: 1px 1px 10px rgba(#000, .1);
      position: relative;
      cursor: grab;
      &--message {
        position: absolute;
        z-index: 100;
        bottom: calc(100% + 12px);
        left: 0;
        width: 100%;
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(#000, .4);
        font-size: 12px;
        transition: all .5s ease;
        opacity: 0;
        visibility: hidden;
        translate: 0 10px;
        &.is-show {
          opacity: 1;
          visibility: visible;
          translate: 0 0;
        }
        &::before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: #fff transparent transparent transparent;
          position: absolute;
          bottom: -10px;
          left: 10px;
        }
        p {
          margin-bottom: 0;
        }
      }
      &.is-show {
        .list__card--detail {
          display: block;
        }
        .list__card--more {
          display: none;
        }
      }
      &--detail {
        margin-top: 8px;
        display: none;
      }
      &--more {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 8px;
        p {
          margin: 0;
        }
        .gicon {
          color: #A7A7A7;
          transform-origin: center;
          rotate: 90deg;
        }
      }
      &--category {
        font-size: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      &--title {
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        margin-top: 8px;
        overflow: hidden;
      }
      &--desc {
        font-size: 12px;
        height: 3em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      &--price {
        display: flex;
        font-size: 14px;
        border: solid 1px #F4F4F4;
        border-radius: 4px;
        margin-top: 8px;
        margin-bottom: 0;
        dt {
          background-color: #F4F4F4;
          width: 78px;
          font-weight: 400;
          text-align: center;
          padding-block: 4px;
        }
        dd {
          flex-grow: 1;
          text-align: right;
          padding-block: 4px;
          padding-inline: 8px;
          margin-bottom: 0;
        }
      }
      &--buttons {
        display: flex;
        gap: 4px;
        margin-top: 10px;
        .btn-grad.silver {
          max-width: 74px;
          text-align: left;
          padding-inline: 1.14em;
          .gicon {
            right: 7px;
          }
        }
        .btn-grad.gray {
          padding-inline: 1.14em;
        }
        .gicon {
          font-size: 16px;
        }
      }
      .btn-unaccept {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 14px;
        margin: 4px auto 0;
        cursor: pointer;
        .gicon {
          font-size: 16px;
        }
      }
      .btn-open {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: linear-gradient(0deg, #545D5C 0%, #768180 100%);
        width: 20px;
        height: 20px;
        border-radius: 0 4px 0 0;
        position: absolute;
        top: 0;
        right: 0;
        .gicon {
          font-size: 14px;
        }
      }
      &.is-warning {
        padding-left: 7px;
        padding-right: 14px;
        border-left: solid 5px #f00;
        .list__card--category {
          display: flex;
          align-items: center;
          gap: 4px;
          &::before {
            content: '\e002';
            font-family: 'Material Icons';
            color: #f00;
            font-size: 26px;
          }
        }
      }
      &.is-hide {
        display: none;
      }
    }
  }

  // モーダル
  .modal {
    background-color: rgba(#000, .45);
    &__wrapper {
      background-color: #F4F4F4;
      width: 930px;
      max-height: calc(100vh - 40px);
      padding: 60px 40px 40px;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
    }
    &__block {
      background-color: #F4F4F4;
      max-height: calc(640px - 60px - 40px - 60px - 60px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent
      }
      &::-webkit-scrollbar-thumb {
        background-color: #A7A7A7;
        border-radius: 9999px
      }
      &--head {
        display: flex;
        align-items: center;
        gap: 10px;
        &.modal__caution {
          display: flex;
          align-items: center;
          gap: 6px;
          color: #f00;
          &::before {
            content: '\e002';
            font-family: 'Material Icons';
            font-size: 30px;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
      &--category {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        margin-top: 10px;
        p {
          &:nth-of-type(n + 2) {
            &::before {
              content: '｜';
            }
          }
        }
      }
      &--title {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
      }
      &--detail {
        display: flex;
        flex-wrap: wrap;
        gap: 0 30px;
        margin-top: 20px;
      }
      &--swiper {
        width: calc(100% - 50px);
        margin-inline: auto;
        margin-bottom: 1rem;
        position: relative;
        .swiper-button-prev, .swiper-button-next {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 100%;
          position: absolute;
          top: 0;
          z-index: 50;
          cursor: pointer;
        }
        .swiper-button-prev {
          left: -20px;
        }
        .swiper-button-next {
          right: -20px;
        }
        img {
          width: 100%;
          height: 220px;
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }
    &__text {
      &--title {
        font-size: 24px;
      }
      &--status {
        color: #fff;
        font-weight: 700;
        padding: 4px 20px;
        border-radius: 9999px;
        &.is-status-1 {
          background-color: #00A8F2;
        }
        &.is-status-2 {
          background-color: #0027FF;
        }
        &.is-status-3 {
          background-color: #F27600;
        }
        &.is-status-4 {
          background-color: #00B842;
        }
        &.is-status-5 {
          background-color: #757575;
        }
      }
      &--caution {
        margin-left: 10px;
      }
    }
    &__detail {
      display: flex;
      width: 100%;
      padding-bottom: 5px;
      border-bottom: solid 1px #CED4DA;
      &.is-half {
        width: calc((100% - 30px) / 2);
      }
      &--title {
        font-size: 14px;
        font-weight: 300;
        width: 140px;
        min-width: 140px;
      }
    }
    &__price {
      display: flex;
      background-color: #fff;
      width: calc((100% - 30px) / 2);
      border-radius: 4px;
      overflow: hidden;
      &--title {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #CED4DA;
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        width: 124px;
        min-width: 124px;
      }
      &--desc {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(100% - 124px);
        height: 100%;
        padding-inline: 10px;
        span {
          font-size: 12px;
        }
      }
    }
    .regist-button {
      margin-top: 60px;
    }
    .btn-close {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: linear-gradient(0deg, #545D5C 0%, #768180 100%);
      width: 50px;
      height: 50px;
      border-radius: 0 4px 0 0;
      position: absolute;
      top: 0;
      right: 0;
      .gicon {
        font-size: 30px;
      }
    }
    &__deal {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 2rem;
      .is-half {
        width: calc((100% - 1rem) / 2);
      }
    }
  }

  // 登録フォーム
  .add {
    .is-other {
      display: none;
    }
    &__block {
      margin-top: 40px;
      .fileSelect {
        display: block;
        width: fit-content;
        margin-top: 20px;
        margin-bottom: 0;
        cursor: pointer;
        p {
          font-size: 14px;
          width: fit-content;
          background-color: #fff;
          border: solid 2px #E6E6E6;
          border-radius: 4px;
          padding: 5px 20px;
          margin: 0;
        }
        input {
          display: none;
        }
      }
    }
    &__caution {
      color: #f00;
      font-size: 12px;
    }
    &__img {
      max-width: 100%;
    }
    &__file {
      margin-top: 10px;
    }
    &__title {
      display: flex;
      align-items: center;
      gap: 10px;
      & > label {
        font-size: 12px;
        font-weight: 700;
        margin: 0;
      }
    }
    &__meeting {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 662px;
      margin-top: 10px;
      .custom-control {
        margin: 0;
      }
      &--select {
        width: 500px;
      }
      &--switch {
        position: relative;
        &.is-disabled {
          &::after {
            content: '';
            background-color: rgba(#fff, .8);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    &__basic {
      width: 392px;
      margin-top: 10px;
      &--name {
        display: flex;
        gap: 30px;
        .md-form {
          width: calc((100% - 30px) / 2);
        }
      }
    }
    &__full {
      margin-top: 10px;
    }
    &__business {
      display: none;
      &.is-show {
        display: block;
      }
      &--category {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 10px;
        &:nth-of-type(n + 2) {
          margin-top: 20px;
        }
        .add__title {
          width: 100%;
        }
        &-item {
          display: flex;
          align-items: center;
          gap: 20px;
          width: calc((100% - 24px) / 3);
        }
        .custom-control {
          margin: 0;
        }
        .md-form {
          margin: 0;
        }
      }
    }
    &__flex {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
      &--nav {
        width: 160px;
      }
      &--content {
        flex-grow: 1;
      }
    }
    &__product {
      &[data-status] {
        display: none;
      }
      &.is-show {
        display: block;
      }
      &:nth-of-type(n + 2) {
        margin-top: 50px;
      }
      &--title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #E6E6E6;
        font-size: 18px;
        padding: 10px 20px;
        p {
          margin-bottom: 0;
        }
        button {
          display: flex;
          align-items: center;
          gap: 9px;
          span {
            color: #f00;
            font-size: 30px;
          }
          p {
            font-size: 14px;
          }
        }
      }
      &--block {
        display: flex;
        align-items: flex-start;
        padding-inline: 20px;
        margin-top: 20px;
        & > label {
          width: 110px;
          min-width: 110px;
          margin: .5em 0 0;
          font-weight: 700;
        }
        & > div {
          flex-grow: 1;
        }
      }
      .custom-price {
        width: 230px;
        span {
          position: absolute;
          right: 0;
          bottom: 0.4em;
        }
      }
      .custom-date {
        width: 230px;
      }
      .custom-label {
        display: flex;
        align-items: center;
        gap: 10px;
        &__image {
          min-width: 72px;
          border-radius: 4px;
          object-fit: cover;
        }
        &__block {
          flex-grow: 1;
          p {
            margin: 0;
          }
          &--label {
            font-size: 16px;
          }
          &--detail {
            display: flex;
            gap: 30px;
            color: #5C5C5C;
            margin-top: 5px;
          }
        }
      }
    }
    &__nav {
      margin: 20px 0 0 130px;
    }
    &__plus {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #fff;
      background-color: #00B842;
      padding: 10px 20px 10px 10px;
      border-radius: 9999px;
      img {
        width: 28px;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    .js-block-contract {
      display: none;
      &.is-show {
        display: block;
      }
      &:nth-of-type(n + 2) {
        margin-top: 50px;
      }
    }
    &__imglist {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 20px;
      margin-top: 20px;
      &--item {
        width: calc((100% - 60px) / 4);
        text-align: center;
        position: relative;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #f00;
          background-color: rgba(#000, .4);
          width: 30px;
          height: 30px;
          border-radius: 9999px;
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
    }
  }
  .regist-button {
    margin-top: 50px;
    button {
      &:nth-of-type(n + 2) {
        margin-top: 40px;
      }
    }
  }
}
